import ar from "@/assets/i18n/ar.json";
import de from "@/assets/i18n/de.json";
import en from "@/assets/i18n/en.json";
import es from "@/assets/i18n/es.json";
import fr from "@/assets/i18n/fr.json";
import it from "@/assets/i18n/it.json";
import ja from "@/assets/i18n/ja.json";
import ko from "@/assets/i18n/ko.json";
import nl from "@/assets/i18n/nl.json";
import pl from "@/assets/i18n/pl.json";
import sv from "@/assets/i18n/sv.json";
import tr from "@/assets/i18n/tr.json";

const langs = [
    { code: "de", iso: "de-DE", file: "de.json" },
    { code: "en", iso: "ed-EN", file: "en.json" },
    { code: "fr", iso: "fr-FR", file: "fr.json" },
    { code: "nl", iso: "nl-NL", file: "nl.json" },
    { code: "ar", iso: "ar-AR", file: "ar.json" },
    { code: "es", iso: "es-ES", file: "es.json" },
    { code: "it", iso: "it-IT", file: "it.json" },
    { code: "ja", iso: "ja-JA", file: "ja.json" },
    { code: "ko", iso: "ko-KO", file: "ko.json" },
    { code: "pl", iso: "pl-PL", file: "pl.json" },
    { code: "sv", iso: "sv-SV", file: "sv.json" },
    { code: "tr", iso: "tr-TR", file: "tr.json" },
];

export default defineI18nConfig(() => ({
    legacy: false,
    locale: "de",
    globalInjection: true,
    mode: "composition",
    types: "composition",
    availableLocales: langs,
    locales: langs,
    vueI18nLoader: false,
    parsePages: false,
    fallbackLocale: "de",
    strictMessage: false,
    precompile: {
        strictMessage: false,
    },
    messages: {
        en,
        de,
        fr,
        nl,
        ar,
        es,
        it,
        ja,
        ko,
        pl,
        sv,
        tr,
    },
}));
